//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import SparePartForm from '@/modules/sparePart/components/sparePart-form';
import { routerAsync } from '@/app-module';
import i18n from '@/vueI18n'

export default {
  name: 'app-sparePart-form-page',

  props: ['id'],

  components: {
    [SparePartForm.name]: SparePartForm,
  },

  computed: {
    ...mapGetters({
      record: 'sparePart/form/record',
      findLoading: 'sparePart/form/findLoading',
      saveLoading: 'sparePart/form/saveLoading',
    }),

    isEditing() {
      return !!this.id;
    },
    language(){
      return i18n.locale
    }
  },

  async created() {
    if (this.isEditing) {
      await this.doFind(this.id);
    } else {
      await this.doNew();
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('menu.spareParts') 
    },
  },

  methods: {
    ...mapActions({
      doFind: 'sparePart/form/doFind',
      doNew: 'sparePart/form/doNew',
      doUpdate: 'sparePart/form/doUpdate',
      doCreate: 'sparePart/form/doCreate',
    }),

    doCancel() {
      routerAsync().push('/sparePart');
    },

    i18n(key, args) {
      return this.$t(key, args);
    },

    async doSubmit(payload) {
      if (this.isEditing) {
        return this.doUpdate(payload);
      } else {
        return this.doCreate(payload.values);
      }
    },
  },
};
