//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { SparePartModel } from '@/modules/sparePart/sparePart-model';
import i18n from '@/vueI18n';

const { fields } = SparePartModel;
const formSchema = new FormSchema([
  fields.id,
  fields.name,
  fields.brand,
  fields.barcode,
  fields.features,
  fields.quantity,
  fields.warrantyPeriod,
  fields.photo,
  fields.qrCode,
]);


export default {
  name: 'app-sparePart-form',

  props: ['isEditing', 'record', 'saveLoading', 'modal'],

  data() {
    return {
      step: 1,
      model: null,
      active: false,
      rules: formSchema.rules(),
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
    if (!this.isEditing) {
      this.model.warrantyPeriod = 0
      this.model.quantity = 0
    } else if (this.model.photo && !Array.isArray(this.model.photo)) {
      this.model.photo = [this.model.photo]
    }
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      is_screen_xs: 'layout/is_screen_xs',
    }),
    language(){
      return i18n.locale
    },

    fields() {
      return fields;
    },
    UrlExample() {
      return this.language == 'ar'
        ? 'مثال: https://www.puricomegypt.com/ro-system'
        : 'Example: https://www.puricomegypt.com/ro-system'
    },
  },

  methods: {
    doReset() {
      this.model = formSchema.initialValues(this.record);
    },
     i18n(key, args) {
      return this.$t(key, args);
    },
    doCancel() {
      this.$emit('cancel');
    },
    async doSubmit() {
      // try {
      //   await this.$refs.form.validate();
      // } catch (error) {
      //   return;
      // }

      if (this.step < 2) {
        return this.$refs.stepper.next()
      }
      
      const { id, ...values } = formSchema.cast(this.model);
      this.model.photo.length
        ? values.photo = this.model.photo[0]
        : values.photo = null
      return this.$emit('submit', {
        id,
        values,
      });
    },
  },
  watch: {
    model: {
      handler(){
        if (this.model.warrantyPeriod < 0) {
          this.model.warrantyPeriod = 0
        } 
        if (this.model.quantity < 0) {
          this.model.quantity = 0
        } 
      },
      deep: true
    },
  },
};
